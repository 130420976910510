.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 90vh; */
    background-image: url("./assets/images/banner2.svg");
    background-size: cover;
    background-position: center;
    /* text-align: start; */
    position: relative;
    /* padding-right: 30px; */
    /* top: 0px; */
    flex-direction: column;
}

.payment-banner {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    background-image: url("./assets/images/payment.jpg");
    background-size: cover;
    background-position: center;
}

.testimonial-banner {
    background-image: url("./assets/images/testimonial.jpg");
    background-size: cover;
    background-position: center;
}

.banner-contact {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("./assets/images/contactus.svg");
    background-size: cover;
    background-position: center;
    /* text-align: start; */
    position: relative;
    /* padding-right: 30px; */
    /* top: 0px; */
    flex-direction: column;
}

.project-detail-banner {
    background-image: url("./assets/images/banner3.svg");
    background-size: cover;
    background-position: center;
}
.project-detail-banner-404 {
    height: 90vh;
    background-image: url("./assets/images/404.svg");
    background-size: cover;
    background-position: top;
}
.deliverables-banner {
    background-image: url("./assets/images/deliverables.svg");
    background-size: cover;
    background-position: center;
}

.deliverables-banner-text {
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.project-detail-banner-text {
    /* border: 1px solid #cacaca; */
    color: white;
    border-radius: 8px;
    backdrop-filter: blur(20px);
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.col {
    display: flex;
    flex-direction: column;
}
.text {
    /* width: 50%; */
    width: auto;
    border-radius: 8px;
    backdrop-filter: blur(10px);
    /* border: 1px solid #c0c0c0; */
}

.header-btn {
    color: pink;
    background-color: pink;
    border: 1px solid black;
}

.text-about {
    backdrop-filter: blur(100px);
}

.about-image {
    /* height: auto; */
    background-image: url("./assets/images/banner1.svg");
    background-size: cover;
    backdrop-filter: blur(10px);
    background-position: center;
}

::-webkit-scrollbar {
    width: 0px;
}

.slick-list {
    padding: 0 !important;
    margin: 0 !important;
}
.slick-slide {
    margin: 0 2px;
}

.purple {
    /* color: #2f315b; */
    /* color: #7f85f5; */
    /* color: #4851eb; */
    color: #30369e;
}

/* Center the slides properly */
.slick-track {
    display: flex !important;
    align-items: center !important; /* Vertically align the content to the center */
}

/* Style the dots below the slider to avoid extra space */
.slick-dots {
    margin-top: 0 !important; /* Remove unwanted top margin */
    padding: 0 !important; /* Remove padding around dots */
}

/* Control the size and position of dots to reduce space */
.slick-dots li button:before {
    font-size: 10px; /* Control dot size to reduce height */
}

.slick-center {
    transform: scale(1.05); /* Slightly enlarge the centered card to highlight it */
    transition: transform 0.7s ease-in-out;
}

/* Optional: Fix height for the carousel */
.slick-slide {
    height: auto; /* Auto height based on content */
    display: flex;
    justify-content: center;
}

/* Initially hidden state */
.hidden {
    opacity: 0;
    transform: translateY(150px); /* Start with a slight downward shift */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* Slide-in animation when the element is in view */
.slide-in {
    opacity: 1;
    transform: translateY(0); /* Slide to original position */
}

.text-center {
    text-align: center;
}

.outlet {
    width: calc(100vw - 65px);
}

.scrolling-banner {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    position: relative;
}

.scrolling-banner .scroll {
    padding-left: 40%;
    animation: scroll-left 14s linear infinite;
}

.scrolling-banner .scroll:hover {
    animation-play-state: paused;
}

@keyframes scroll-left {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@media screen and (max-width: 766px) {
    .project-title {
        font-size: 12px !important;
    }
    .row {
        flex-direction: column;
        gap: 0px;
    }

    .deliverables-banner,
    .about-image,
    .payment-banner,
    .banner-contact {
        background-image: none;
    }
    .payment-banner,
    .banner-contact {
        height: 0;
    }
    .deliverables-banner-text {
        backdrop-filter: blur(50px);
    }
    .MuiListItemText-root {
        font-weight: bold;
    }
    .project-detail-banner-text {
        backdrop-filter: blur(100px);
        /* color: black; */
    }
    .banner {
        height: 85vh;
    }
    .project-detail-banner-404 {
        height: 35vh;
    }
    .text {
        width: auto;
    }
}
